import axios from 'axios'
import store from '../../../store/store.js'

export default class ProductService {
  async update(item, id) {
    try {
      var response = await axios.put(
        store.state.apiURL +
        "/api/productitem/" +
        id, {
          name: item.name,
          code: item.code,
          category: item.category,
          texture: item.texture,
          itemCode3Digit: item.itemCode3Digit,
          color: item.color,
          size: item.size,
          yearth: item.yearth,
          gender: item.gender,
          inStock: item.inStock,
          rentalPrice: item.rentalPrice,
          bail: item.bail,
          itemStatus: item.itemStatus,
          webCategory: item.webCategory,
          webHotItem: item.webHotItem,
          temporary: item.temporary,
          description: item.description,
          newRecord: item.newRecord,
          mainImage: item.mainImage,
          barcodeImage: item.barcodeImage,
          state: item.state
        },
        this.$store.state.auth.apiHeader
      );

      if (response.status === 200) {
        // this.noticeSuccess("ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว");
        // console.log("response.data ", response.data);
      }
    } catch (err) {
      // console.log("SAVE ERROR >>> ", err);
      this.noticeError("save error");
    }
  }
  async remove(id) {

    var response = await axios.delete(
      store.state.apiURL +
      "/api/productitem/" +
      id, this.$store.state.auth.apiHeader
    );


    return response;


  }
}
