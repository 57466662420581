<template>
  <vx-card>
    <!-- ZOOM POP UP -->
    <template>
      <!-- ZOOM IMAGE POP UP -->
      <vs-popup
        classContent="popup-example label-font "
        title="Stock Image"
        :active.sync="zoomPopupActive"
      >
        <div style="width:400px; margin: 5px auto;">
          <img class="responsive rounded" :src="zoomzoom" style="width:95%;" />
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <!-- BRANCHES -->
    <!-- PAGES  จำนวนข้อมูลที่แสดง  -->
    <vs-row
      class="row-form"
      vs-type="flex"
      vs-justify="flex-end"
      vs-w="12"
      style="margin-top:30px;"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="1"
        class="form-label px-2"
      >จำนวน</vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-lg="3"
        vs-sm="3"
        vs-xs="3"
        class="form-label px-2"
      >
        <v-select
          style="width: 100%;"
          v-model="pageitemSelected"
          :options="pageitemOptions"
          :select="pageitemSelected"
        ></v-select>
      </vs-col>
    </vs-row>
    <!--  PAGES -->

    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table
      :sst="true"
      @change-page="handleChangePage"
      style="z-index:1;"
      pagination
      :max-items="pageitemSelected"
      search
      :data="itemsInStock"
    >
      <!-- TABLE HEADER -->

      <!-- TABLE BODY -->
      <template style="z-index:1 !important;" slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <img
              @click="zoomimage(tr.mainImage)"
              class="responsive rounded"
              :src="getFullpath(tr.mainImage)"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>
          <!-- itemName -->
          <vs-td style=" width:200px;">
            <span
              @click="editItem($event, tr.productItemId)"
              style="color: #2372CE; font-weight:bold;"
            >{{ tr.name }}</span>
            <br />
            <b>รหัส : {{ getBarcode(tr.code) }}</b>
            <br />
            <b>ประเภท :</b>
            {{ getCategoryName(tr.category) }}
            <br />
            <!-- &nbsp;&nbsp;&nbsp; -->
            <b>สี :</b>
            {{ tr.color }}
            <br />
            <!-- แบ่งแยกกลุ่ม -->
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <vs-row vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  v-if="tr.group === 'c'"
                >
                  <div class="sm-btn-black">c</div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  v-if="tr.group === 'm'"
                >
                  <div class="sm-btn-yellow">m</div>
                </vs-col>
              </vs-row>
            </div>

            <!-- แบ่งแยกกลุ่ม -->
            <div
              style="width:75%;"
              v-if="tr.groupId === itemId"
              @click="changeGroup(tr.productItemId)"
              class="sm-btn-yellow"
            >เปลี่ยนสินค้าหลัก</div>
          </vs-td>

          <!-- <vs-td>{{tr.itemCategory}}</vs-td>
          <vs-td>{{tr.itemColor}}</vs-td>-->
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->
          <vs-td v-if="tr.groupId === itemId" style="width:100px;">
            <div @click="removefromgroup(tr.productItemId)" class="sm-btn-red">ลบออก</div>
          </vs-td>
          <vs-td v-else style="width:100px;">
            <div @click="addintogroup(tr.productItemId)" class="sm-btn-green">เพิ่มเข้า</div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "@/mixins/shapeFormat.js";
import ProductService from "./Services/ProductService";
import { setTimeout } from "timers";
// import Selection1 from "./Components/Selection1";

const productService = new ProductService();

export default {
  props: {
    // list: Array,
    itemId: String,
    incase: String
  },
  mixins: [shapeFormat],
  data() {
    return {
      branchrad: "",
      borValid: 0,
      checkRes: [],
      borrowReq: [],
      borrowBranchId: "",
      branchRadio: [],
      otherBranches: [],
      firstOtherBranch: {},
      reservedq: [],
      checkqq: [],
      name: "",
      name2: ["check1", "check2", "check3"],
      thisBranch: {},
      dlist: [],
      itemsInBranch: [],
      currentBranch: "", // BRANCH ID
      otherBranch: [],
      startingDateValue: new Date(),
      endingDateValue: null,
      theCheckIn: new Date(),
      theCheckOut: null,
      zoomPopupActive: false,
      zoomzoom: "",
      lastPage: 0,
      pageitemSelected: 16,
      pageitemOptions: [8, 16, 24, 32, 40, 48],
      removeId: "",
      removePopupActive: false,
      removeIndex: -1,
      removeCode: "",
      currentUser: "guest",
      // allBranchCheckbox: true,
      btnActive: true,
      panelId: "",
      categories: [],
      code3Digit: "000",
      loadedlist: null,
      anewCheckIn: "2019-04-01",
      checkInDate: "2019-04-01",
      checkOutDate: "2019-06-01",
      checkq: "",
      tryselect: "เมืองทองธานี",
      maximumBorrowedItemAmount: 0,
      borrowedItemAmount: 0,
      selectedBranch: "เลือกสาขา",
      branchOptions: ["เมืองทองธานี", "ราชพฤกษ์"],
      selectedDate: {
        start: "",
        end: ""
      },
      remainingItems: 0,
      currentAmountInstock: 0,
      assignItems: [],
      assignItemPanelActive: false,
      indexToAssign: 0,
      borrowPanelActive: false,
      borrowList: [],
      blist: [],
      checkqueuePanelActive: false,
      checkqueueList: [],
      startDate: undefined,

      instart: undefined,
      inend: undefined,
      startPoint: "2019-05-01",
      endPoint: "2019-05-03",
      checkqueueItem: {
        itemImage: "http://localhost:8088/images/item/defaultimage.png",
        // itemImage:
        //   "https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwirvNWq2NLiAhWeiXAKHRt0Bb0QjRx6BAgBEAU&url=%2Furl%3Fsa%3Di%26source%3Dimages%26cd%3D%26ved%3D%26url%3Dhttps%253A%252F%252Fwww.allure.com%252Fstory%252Ffashion-nova-plus-size-controversy%26psig%3DAOvVaw1PNvitmeBB7YkiDCzMkVuq%26ust%3D1559836059922993&psig=AOvVaw1PNvitmeBB7YkiDCzMkVuq&ust=1559836059922993",
        itemName: "Item Name",
        AmountInStock: 0,
        rentalPrice: 0,
        bail: 0,
        itemCategory: "ไม่ระบุ",
        itemColor: "ไม่ระบุ",
        updateDate: "2019-04-23",
        rentalStat: 0,
        itemStatus: false
      },
      rentalQueue: [
        {
          customerName: "คุณเบน",
          startDate: "2019-04-23",
          endDate: "2019-04-25"
        },
        {
          customerName: "คุณจอน",
          startDate: "2019-05-12",
          endDate: "2019-05-15"
        }
      ],

      itemsInStock: [],

      branches: ["ลาดพร้าว", "เมืองทองธานี"],

      statusToReturn: "คืนชุดแล้ว",
      statusToReturnOption: [
        { text: "คืนชุดแล้ว", value: "คืนชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,
      searchDate: "",
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        { text: "Alipay/wechat", value: "Alipay/wechat" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: []
    };
  },
  computed: {
    allBranchCheckbox: {
      get: function() {
        var all = true;

        if (this.branchCheckbox.length === this.branches.length) {
          all = true;
        } else {
          all = false;
        }

        return all;
      },
      set: function() {}
    }
  },

  async mounted() {
    // LOAD CATEGORY
    // this.$vs.loading({ type: "radius" });

    this.currentUser = JSON.parse(localStorage.getItem("username"));
    var responseCategory;
    try {
      responseCategory = await this.$http.get(
        this.$store.state.apiURL + "/api/category",
        this.$store.state.auth.apiHeader
      );
      this.categories = await responseCategory.data;
    } catch (error) {
      this.shapeFormat.noticeError(error);
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // LOAD BRANCH
    var responseBranch = null;
    try {
      responseBranch = await this.$http.get(
        this.$store.state.apiURL + "/api/branch",
        this.$store.state.auth.apiHeader
      );
    } catch (error) {
      responseBranch = null;
      this.shapeFormat.noticeError(error);
    }

    if (responseBranch.status !== null) {
      this.branches = await responseBranch.data.filter(x => x.state === true);
      // FIND OTHER BRANCH
      this.otherBranches = await responseBranch.data.filter(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
      this.firstOtherBranch = await responseBranch.data.find(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
    }

    // LOAD Stock
    var responseStock = null;
    try {
      responseStock = await this.$http.get(
        this.$store.state.apiURL + "/api/stock",
        this.$store.state.auth.apiHeader
      );
      // console.log("STOCK RESPONSEStock >>> ", responseStock.data);
    } catch (error) {
      responseStock = null;
      this.shapeFormat.noticeError(error);
      // appropriately handle the error
    }

    if (responseStock !== null) {
      this.thestock = await responseStock.data;
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // LOAD ITEM
    var response = null;
    try {
      response = await this.$http.get(
        this.$store.state.apiURL + "/api/productitem",
        this.$store.state.auth.apiHeader
      );
    } catch (error) {
      response = null;
    }

    if (response !== null) {
      // this.noticeSuccess("ทำการบันทึกสำเร็จ");

      setTimeout(() => {
        // this.$vs.loading.close();
      }, 1000);
      // this.itemsInStock = await response.data;
      // this.originalList = await response.data;
      // console.log("PRODUCT response", response.data);
    } else {
      setTimeout(() => {
        // this.$vs.loading.close();
        this.noticeError("เกิดความผิดพลาดขึ้น");
      }, 1000);
    }

    // FIND CURRENT BRANCH ID !!
    this.currentBranch = this.$store.state.auth.mybranchid;

    // FIND OTHER BRANCH
    // this.otherBranch = this.branches.filter(
    //   br => br.branchId !== this.$store.state.auth.mybranchid
    // );

    // console.log(" this.otherBranches>>> ", this.otherBranches);

    switch (this.incase) {
      case "inGroup":
        this.itemsInStock = await response.data.filter(
          n => n.groupId === this.itemId && n.productItemId !== this.itemId
        );
        this.originalList = await response.data.filter(
          n => n.groupId === this.itemId && n.productItemId !== this.itemId
        );
        break;
      case "all":
        this.itemsInStock = await response.data.filter(
          n => n.groupId !== this.itemId && n.productItemId !== this.itemId
        );
        this.originalList = await response.data.filter(
          n => n.groupId !== this.itemId && n.productItemId !== this.itemId
        );
        break;

      default:
        break;
    }

    if (this.$store.state.auth.permission === "staff") {
      this.itemsInStock = await this.removeZero(this.itemsInStock);
      this.originalList = await this.removeZero(this.originalList);
    }

    // >>>>>>>>>>> FOR CHECK QUEUE >>>>>>>>>>>>>>

    this.orders = await this.loadAllOrderShare();
    this.dlist = await this.loadAllDetailShare();
    this.thisBranch = await this.getBranch(this.$store.state.auth.mybranchid);
    this.branchrad = this.$store.state.auth.mybranchid;

    // >>>>>>>>>>> FOR CHECK QUEUE >>>>>>>>>>>>>>
  },

  methods: {
    async changeGroup(productId) {
      var res = await this.$http.post(
        this.$store.state.apiURL + "/api/Nbs4u0m9pehXse/changemain",
        {
          productItemId: productId,
          updateBy: this.currentUser
        },
        this.$store.state.auth.apiHeader
      );

      var data = await res;
      if (data != null) {
        this.itemid = productId;
        // console.log("[STOCK LIST] AFTER CHANGE PROD >>>> ", this.itemId);

        // var newkey = this.generateRandom(5);
        this.$emit("changegroup", productId);
        setTimeout(() => {
          this.$router.push({
            path: "/backoffice/add-group/" + productId
          });
        }, 1000);
      }
    },
    async addintogroup(productId) {
      await this.$http.post(
        this.$store.state.apiURL + "/api/Nbs4u0m9pehXse/pushgroup",
        {
          groupId: this.itemId,
          productItemId: productId,
          updateBy: this.currentUser
        },
        this.$store.state.auth.apiHeader
      );
      var newkey = this.generateRandom(5);
      this.$emit("setNewkey", newkey);
    },
    async removefromgroup(productId) {
      await this.$http.put(
        this.$store.state.apiURL +
          "/api/Nbs4u0m9pehXse/removegroup/" +
          productId
      );
      var newkey = this.generateRandom(5);
      this.$emit("setNewkey", newkey);
    },
    async submitBorrow(item, index) {
      this.$vs.loading({ type: "radius" });
      var theItem = {
        productItemId: item.productItemId,
        approve: 0,
        startDate: this.formatDate(item.pickupDate),
        endDate: this.formatDate(item.returnDate),
        fromBranch: this.$store.state.auth.mybranchid,
        toBranch: this.branchRadio[index],
        qty: this.reservedq[index],
        updateBy: this.currentUser
      };
      console.log(
        "submitBorrow >>>> start >>>> ",
        theItem.startDate,
        "end >>> ",
        theItem.endDate
      );

      var res = null;
      try {
        res = await this.$http.post(
          this.$store.state.apiURL + "/api/borrow",
          theItem,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("เกิดข้อผิดพลาดไม่สามารถส่งข้อมูลขอยืมชุดได้");
        }, 500);
      }
      if (res !== null) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("ส่งข้อมูลเพื่อขอยืมชุดแล้ว");
          this.borrowPanelActive = false;
        }, 500);
      }
    },
    selection1_result(ind, value) {
      this.blist[ind].branchId = value;
      this.branchRadio[ind] = value;
      this.borValid = 0;
      console.log(
        "ind >>> ",
        ind,
        "this.blist[ind].branchId >>> ",
        this.blist[ind].branchId
      );
    },
    getCustomerName(orderid) {
      // console.log("getCustomerName >>>> ", orderid);

      var ord = this.orders.find(x => x.orderHeaderId === orderid);

      if (ord === null || ord === undefined) {
        return "";
      } else {
        return ord.customerName;
      }
    },
    removeZero(list) {
      var tmp = [];
      var i = 0;
      if (this.$acl.check("staff")) {
        for (i = 0; i < list.length; i++) {
          if (this.otherstock(list[i].productItemId) > 0) {
            tmp.push(list[i]);
          }
        }
      } else {
        for (i = 0; i < list.length; i++) {
          tmp.push(list[i]);
        }
      }

      return tmp;
    },
    seq(ind) {
      var seqnum = Number(ind);
      if (!isNaN(seqnum)) {
        return (seqnum + 1).toString() + " .";
      } else {
        return "0 .";
      }
    },
    async getBranch(id) {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch/" + id,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadProduct() {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
          this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    qCheckIn: function(newDate, index) {
      this.checkqueueList[index].startDate = newDate;
    },
    qCheckOut: function(newDate, index) {
      this.checkqueueList[index].endDate = newDate;
    },

    bCheckIn: function(newDate, index) {
      this.borValid = 0;
      console.log("this.borValid ", this.borValid);
      this.blist[index].pickupDate = newDate;
    },
    bCheckOut: function(newDate, index) {
      this.borValid = 0;
      console.log("this.borValid ", this.borValid);
      this.blist[index].returnDate = newDate;
    },

    newCheckIn: function(newDate) {
      // console.log("A new check in >>> ", newDate);
      this.theCheckIn = newDate;
    },
    newCheckOut: function(newDate) {
      // console.log("A new check out >>> ", newDate);
      this.theCheckOut = newDate;
    },

    otherstock(productId) {
      var st = this.thestock.filter(
        x =>
          x.productItemId === productId &&
          x.branchId !== this.$store.state.auth.mybranchid
      );
      if (st === null || st === undefined || st === "") {
        return 0;
      } else {
        var res = st.reduce((result, { inStock }) => result + inStock, 0);
        return res;
      }
    },
    zoomimage(image) {
      this.zoomzoom = this.$store.state.imageStore + image;
      this.zoomPopupActive = true;
    },

    handleChangePage() {
      console.log("The user changed the page ");
    },

    resetlist() {
      this.itemsInStock = [];
      for (var j = 0; j < this.originalList.length; j++) {
        this.itemsInStock.push(this.originalList[j]);
      }
    },
    getBarcode(code) {
      if (
        code === "" ||
        code === null ||
        code === undefined ||
        code === "01WSW001S"
      ) {
        return "ไม่ได้บันทึก";
      } else {
        return code;
      }
    },
    async reload() {
      this.btnActive = !this.btnActive;
      this.$vs.loading({ type: "radius" });

      // LOAD ITEM
      try {
        // RELOAD STOCK
        var responseStock = await this.$http.get(
          this.$store.state.apiURL + "/api/stock",
          this.$store.state.auth.apiHeader
        );
        this.thestock = await responseStock.data;

        // RELOAD ITEMS
        var response = null;
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");

          this.$vs.loading.close();
        }, 1000);
        this.btnActive = !this.btnActive;
      } else {
        console.log("ALREADY 200");
        setTimeout(async () => {
          this.$vs.loading.close();
          // this.itemsInStock = response.data;
          // this.originalList = response.data;

          // this.noticeSuccess("บันทึกเสร็จสิ้น");
        }, 2000);

        switch (this.incase) {
          case "inGroup":
            this.itemsInStock = await response.data.filter(
              n => n.groupId === this.itemId
            );
            this.originalList = await response.data.filter(
              n => n.groupId === this.itemId
            );
            console.log("this.itemId >>> ", this.itemId);

            break;
          case "all":
            this.itemsInStock = await response.data.filter(
              n => n.groupId !== this.itemId && n.productItemId !== this.itemId
            );
            this.originalList = await response.data.filter(
              n => n.groupId !== this.itemId && n.productItemId !== this.itemId
            );
            break;

          default:
            break;
        }

        if (this.$store.state.auth.permission === "staff") {
          this.itemsInStock = await this.removeZero(response.data);
          this.originalList = await this.removeZero(response.data);
        }

        this.btnActive = !this.btnActive;
      }
    },
    async removeItem(event, ind, item) {
      event.stopPropagation();
      this.removePopupActive = true;
      this.removeIndex = ind;
      this.removeCode = item.code;
      this.removeId = item.productItemId;
    },
    async remove() {
      var resp = await this.$http.delete(
        this.$store.state.apiURL + "/api/productitem/" + this.removeId,
        this.$store.state.auth.apiHeader
      );
      // var resp = await productService.remove(this.$vs.notice, this.removeId);
      if (resp.status === 200) {
        this.noticeSuccess("ลบ " + this.removeCode + " สำเร็จ");
        this.itemsInStock.splice(this.removeIndex, 1);
        this.closeRemovePanel();
      } else {
        this.noticeSuccess("มีข้อผิดพลาด");
      }
    },
    closeRemovePanel() {
      this.removePopupActive = false;
      this.removeIndex = -1;
      this.removeCode = "";
      this.removeId = "";
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },
    changeState(ind) {
      // var state = !this.itemsInStock[ind].state;
      // this.itemsInStock[ind].state = state;
      console.log(
        "ind >>> ",
        ind,
        " this.itemsInStock[ind].state >>> ",
        this.itemsInStock[ind].state
      );

      productService.update(
        this.itemsInStock[ind],
        this.itemsInStock[ind].productItemId
      );
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    async newItem() {
      console.log("NEW ITEM");

      try {
        var response = await this.$http.post(
          this.$store.state.apiURL + "/api/productitem",
          {
            name: "New-Item",
            code: "01WSW001S",
            category: "C",
            texture: "C",
            size: "S",
            yearth: 1,
            gender: "W",
            itemAmount: 1,
            rentalPrice: 0,
            bail: 0,
            itemStatus: "เปิด",
            webCategory: "",
            webHotItem: "ไม่ใช่",
            itemCode3Digit: "000",
            color: "",
            newRecord: true,
            temporary: false,
            state: true,
            updateBy: this.currentUser,
            createBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        var fresposeStatus = await response.status;
        console.log("fresposeStatus >>> ", fresposeStatus);

        var fresposeProductId = await response.data;
        console.log("AFTER response >>> ", response);

        if (fresposeStatus === 200) {
          var branchResp = await this.$http.post(
            this.$store.state.apiURL + "/api/stock",
            {
              branchId: "",
              productItemId: fresposeProductId,
              inStock: 0
            },
            this.$store.state.auth.apiHeader
          );
          var sres = await branchResp.data;
          console.log("branchResp >>> ", sres);
        }

        // Save to stock

        // Redirect to create stock
        if ((await branchResp.status) === 200) {
          await this.$router.push("/backoffice/create-item/" + response.data);
        }
      } catch (error) {
        this.noticeError(error);
      }
    },
    editItem(event, productItemId) {
      event.stopPropagation();
      window.open("/backoffice/create-item/" + productItemId, "_blank");
      // this.$router.push("/backoffice/create-item/" + productItemId);
    },
    onDateSelected: function(daterange) {
      this.selectedDate = daterange;
    },
    // FOR PRINT CARD
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach(b => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    },

    async convertFileToDataURLviaFileReader(url) {
      let resp = await fetch(url);

      console.log("convertFileToDataURLviaFileReader >>> ", resp);

      var base64Flag = await "data:image/png;base64,";
      var imageStr = this.arrayBufferToBase64(await resp.arrayBuffer());
      var imageurl = base64Flag + imageStr;
      return imageurl;
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var cc = document.getElementsByClassName("is-current");
      var page_th = cc[0].getElementsByTagName("span")[0].innerText;
      var serow = document.querySelectorAll("input[data-id]");

      var codelist = [];

      for (var n = 0; n < serow.length; n++) {
        codelist.push(serow[n].getAttribute("data-id"));
      }

      var page_th_num = Number(page_th);
      if (isNaN(page_th_num)) {
        page_th_num = 1;
      }
      // console.log(" trow >>>>>>>>>>>>>>>>>>>>>>>>> ", trow[0].outerHTML);

      if (this.lastPage === 0) {
        //*** เข้ามาครั้งแรก
        this.lastPage = page_th_num;
      } else {
        //*** เปลี่ยนหน้าใหม่
        if (this.lastPage !== page_th_num) {
          this.selectStatus = 0;
          this.selectAll === false;
          this.lastPage = page_th_num;
          this.selectedList = [];
          for (ind = 0; ind < this.itemsInStock.length; ind++) {
            this.itemsInStock[ind].selected = false;
          }
        }
      }

      // ************************************************************************
      var ind = 0;

      // ************************************************************************
      //  คำนวณจำนวนที่ต้องเลือก
      // ************************************************************************

      // var first = (page_th_num - 1) * this.pageitemSelected + 1;
      // var last =
      //   (page_th_num - 1) * this.pageitemSelected + this.pageitemSelected;

      // if (last > this.itemsInStock.length) {
      //   last = this.itemsInStock.length;
      // }

      // ใช้แค่ code ที่ต้องการเลือกมา

      // init selectedList

      // this.selectedList = [];

      // for (var k = 0; k < codelist.length; k++) {
      //   var theind = this.itemsInStock.findIndex(xx => xx.code === codelist[k]);

      //   console.log("[", k, "] theind >>>>> ", theind);

      //   if (theind >= 0) {
      //     this.itemsInStock[theind].selected = true;
      //     this.selectedList.push(this.itemsInStock[theind]);
      //   }
      // }

      // ************************************************************************

      var k = 0;
      var theind = -1;
      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0) {
        console.log("expected >>> 0");
        this.selectedList = [];

        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }

        this.selectStatus = 1;
        console.log("go out with >>> 1");
      } else if (this.selectStatus === 2) {
        console.log("expected >>> 2");
        // this.selectedList = [];
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }
        this.selectStatus = 1;
        console.log("go out with >>> 1");
      } else if (this.selectStatus === 1) {
        console.log("expected >>> 1");
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = false;
        // }
        // this.selectedList = [];

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = false;
          }
        }

        this.selectedList = [];

        this.selectStatus = 0;
        console.log("go out with >>> 0");
      }
      this.fromSelectAll = true;
      // ************************************************************************
      // **  BEGIN : OLD CODE
      // ************************************************************************

      // if (this.selectAll === false && this.selectStatus === 1) {
      //   this.selectAll = true;
      // }
      // if (this.selectStatus === 0 || this.selectStatus === 2) {
      //   // console.log("expected >>> 0 or 2");
      //   this.selectedList = [];
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = true;
      //     this.selectedList.push(this.itemsInStock[ind]);
      //   }
      //   this.selectStatus = 1;
      // } else if (this.selectStatus === 1) {
      //   // console.log("expected >>> 1");
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = false;
      //   }
      //   this.selectedList = [];
      //   this.selectStatus = 0;
      // }
      // this.fromSelectAll = true;

      // ************************************************************************
      // **  END : OLD CODE
      // ************************************************************************
    },
    addSelectedList(tr, event) {
      event.stopPropagation();
      // console.log(this.itemsInStock[indextt].selected);

      var indextt = this.itemsInStock.findIndex(
        x => x.productItemId === tr.productItemId
      );

      console.log(
        "indextt >>>> ",
        indextt,
        " this.itemsInStock[indextt] >>>> ",
        this.itemsInStock[indextt].code
      );

      if (!this.itemsInStock[indextt].selected) {
        this.itemsInStock[indextt].selected = true;
        this.selectedList.push(this.itemsInStock[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.itemsInStock[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.itemsInStock[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.itemsInStock[indextt].selected = !this.itemsInStock[indextt]
        .selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.itemsInStock);
      // console.log(this.selectedList);
    },
    formatPrice(value) {
      // let val = (value / 1).toFixed(2).replace(",", ".");
      console.log(value);

      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  components: {
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect
    // HotelDatePicker
  },
  watch: {
    // branchRadio: async function(value) {
    //   // var bra = this.branches.find(b => b.branchId === value);
    //   this.borrowBranch = value;
    // },
    async branchrad(value) {
      // FIND THIS BRANCH
      this.thisBranch = await this.getBranch(value);

      // FIND OTHER BRANCHES
      this.otherBranches = await this.branches.filter(
        x => x.state === true && x.branchId !== this.thisBranch.branchId
      );
      this.firstOtherBranch = this.branches.find(
        x => x.state === true && x.branchId !== this.thisBranch.branchId
      );

      // console.log("BRANCH RAD >>> ", this.thisBranch);
    },
    reservedq: {
      handler() {
        this.borValid = 0;
      },
      deep: true
    },
    branchCheckbox: {
      handler() {
        if (this.branchCheckbox.length === this.branches.length) {
          this.resetlist();
          this.allBranchCheckbox = true;
        } else {
          if (this.branchCheckbox.length >= 1) {
            for (var i = 0; i < this.originalList.length; i++) {
              var saved = false;
              for (var j = 0; j < this.branchCheckbox.length; j++) {
                if (saved === true) continue;
                var branchname = this.branchCheckbox[j];
                // จะเอา branch ID
                var ab = this.branches.find(x => x.branchName === branchname);
                // หา ID ของ ใน stock
                if (ab != null && ab != undefined) {
                  var stock_branch = this.thestock.find(
                    s =>
                      s.branchId === ab.branchId &&
                      s.productItemId === this.originalList[i].productItemId &&
                      s.inStock > 0
                  );
                  // ได้ id ของ branch เอามาเทียบว่ามี ใน
                  if (stock_branch != null && stock_branch != undefined) {
                    this.itemsInStock.push(this.originalList[i]);
                    saved = true;
                  }
                }
              }
            }
          }
        }
      },
      deep: true
    },

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    assignItems: {
      handler() {
        // var assignedItemsSum = 0;
        // for (var i = 0; i < this.assignItems.length; i++) {
        //   assignedItemsSum = assignedItemsSum + this.assignItems[i].amount;
        // }
        // this.remainingItems = this.currentAmountInstock - assignedItemsSum;
        // console.log("WHEN CHANGE : Item In Stock  >>> ", this.itemsInStock);
      },
      deep: true
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  }
};
</script>

<style>
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}
</style>
